const menuBeverages = [
  {
    id: 1,
    name: 'Water',
    description: [''],
    price: '$2.00',
  },
  {
    id: 2,
    name: 'Soda',
    description: [''],
    price: '$2.50',
  },
  {
    id: 3,
    name: 'Orange Juice',
    description: [''],
    price: '$3.00',
  },
  {
    id: 4,
    name: 'Yogurt Drink',
    description: [''],
    price: '$3.50',
  },
];

export default menuBeverages;
