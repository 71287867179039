const menuSalads = [
  {
    id: 1,
    name: "Tabbouli",
    description: [
      "Fresh minced parsley, cracked wheat, tomatoes, onions, lemon juice, evoo & seasonings",
    ],
    price: "$8.99",
  },
  {
    id: 2,
    name: "Fattoush Salad",
    description: [
      "Crisp Romaine lettuce, tomatoes, cucumbers, red bell peppers, green bell peppers, onions, radish, mint, purslane (seasonal), pita chips, evoo and pomegranate infused dressing.",
    ],
    price: "$8.99",
  },
  {
    id: 3,
    name: "Garden Salad",
    description: [
      "Crisp Romaine lettuce, tomatoes, cucumbers and green pepper tossed in our house dressing.",
    ],
    price: "$7.99",
  },
  {
    id: 4,
    name: "Greek Salad",
    description: [
      "Crisp romaine lettuce, tomatoes, cucumber, green peppers, feta cheese, Kalamata olives and Greek dressing.",
    ],
    price: "$8.99",
  },
];

export default menuSalads;
