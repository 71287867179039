const menuDesserts = [
  {
    id: 1,
    name: "Traditional Baklava",
    description: [""],
    price: "$5.00",
  },
  {
    id: 2,
    name: "Armenian Rice Pudding",
    description: [""],
    price: "$5.00",
  },
];

export default menuDesserts;
